
import { Routes, Route } from "react-router-dom"
import Home from './Home/Home';
import About from "./About/About";
import Products from "./About/Products";
import Social from "./About/Social";
import Careers from "./About/Careers";
import Community from "./Community/Community";
import Diageocares from "./Community/Diageocares";
import Sponsorship from "./Community/Sponsorship";
import News from "./News/News";
import Contact from "./ContactUS/Contact";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/about" element={ <About/> } />
        <Route path="/about/products" element={ <Products/> } />
        <Route path="/about/social" element={ <Social/> } />
        <Route path="/about/careers" element={ <Careers/> } />
        <Route path="/community" element={ <Community/> } />
        <Route path="/community/diageocares" element={ <Diageocares/> } />
        <Route path="/community/sponsorship" element={ <Sponsorship/> } />
        <Route path="/news" element={ <News/> } />
        <Route path="/contact" element={ <Contact/> } />
      </Routes>
    </>
  );
}

export default App;
