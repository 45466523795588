import React from "react";
import { Col, Row, Image, Container } from "react-bootstrap";
import Header from "../Header/Header";
import HomeSlider from "./Home-slider";
import Footer from "../Footer/Footer";

export default function Home() {
  return (
    <div className="bg-black">
      <Header />
      <div className="main-div">
        <Container>
          <HomeSlider />
          <Row>
            <Col md={8}>
              <p>
                In June 2008 the U.S. Virgin Islands and Diageo USVI began a
                public-private initiative for the construction and operation of
                a high capacity rum distillery on St. Croix, U.S. Virgin
                Islands. Today, all Captain Morgan branded spirits for the
                United States market comes from the U.S. Virgin Islands. Captain
                Morgan is a global leader among premium spirits and is the
                second leading rum in the world.
              </p>
              <p>
                For our global resource that promotes responsible drinking
                through the sharing of best practice tools, information and
                initiatives, visit&nbsp;
                <a href="https://www.drinkiq.com/en-us/">DRINKiQ.com</a>.
              </p>
              <p>
                <em>Celebrating life, every day, everywhere.</em>
              </p>
            </Col>
            <Col md={4}>
              <a href="http://www.captainmorganvisitorcenter.com/">
                <Image src="./img/VC_Portal-Red1.png" alt="Image not Found" />
              </a>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="teaser-box style-bordered">
                <div>
                  <h4 className="text-center">
                    <span>DrinkiQ Tips</span>
                  </h4>
                  <hr className="horizontal-break-alt" />
                  <img alt="Cocktail Glass" src="./img/icon_cocktail.png" />
                  <br />
                  DrinkiQ is a global resource designed to help you make
                  responsible choices about drinking.
                  <p>&nbsp;</p>
                  <p className="more">
                    <a href="https://www.drinkiq.com/en-us/">
                      <strong>
                        <span>Find Out More⇒</span>
                      </strong>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="teaser-box ">
                <div>
                  <h4 className="text-center">
                    <span>The Bar</span>
                  </h4>
                  <hr className="horizontal-break-alt" />
                  <img
                    alt="Bottle"
                    data-entity-type="file"
                    data-entity-uuid="f0a77655-e88e-409f-8486-ccb3b9ec4ce3"
                    src=".//img/icon_bottle.png"
                  />
                  <br />
                  Looking for cocktail recipes? Discover delicious recipes
                  complete with ratings, reviews and mixing tips.<p>&nbsp;</p>
                  <p className="more">
                    <a href="http://us.thebar.com/">
                      <strong>
                        <span>Find Out More⇒</span>
                      </strong>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="teaser-box ">
                <div>
                  <h4 className="text-center">
                    <span>Captain Morgan Rum</span>
                  </h4>
                  <hr className="horizontal-break-alt" />
                  <img
                    alt="Barrels"
                    data-entity-type="file"
                    data-entity-uuid="fcf4ce37-6081-40bf-9d85-ff7d5011ad7f"
                    src="./img/icon_barrels.png"
                  />
                  <br />
                  Captain Morgan® branded products are proudly made on St. Croix
                  in the United States Virgin Islands.<p>&nbsp;</p>
                  <p className="more">
                    <a href="http://us.captainmorgan.com/">
                      <strong>
                        <span>Find Out More⇒</span>
                      </strong>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
