import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Usvi_Domain } from "../constant";

export default function Products() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Our Products</h1>
            <p>
              <strong>Captain Morgan Rum Drinks</strong>
              <br />
              Variety is the spice of life. So we’ve spiced up life quite a bit.
              We’ve developed a collection of rum, each{" "}
              <strong>Captain Morgan</strong> drink with its own distinctive
              flavor and personality that is suited for the mate with an
              adventurous spirit.
            </p>
            <p>
              Smooth and medium bodied, Captain Morgan®&nbsp;Original Spiced Rum
              is a secret blend of Caribbean spices and other natural flavors
              that create a mellow, yet satisfying taste. It’s designed to mix
              perfectly with cola. &nbsp;Other Captain Morgan variants include
              Captain Morgan White Rum, Captain Morgan&nbsp;Black Spiced Rum,
              Captain Morgan 100 Proof Spiced Rum, and several other
              great-tasting spirits, each with its own distinctive flavor and
              personality. The Captain Morgan Parrot Bay® Collection&nbsp;brings
              a taste of the tropics to your favorite mixers. It’s a premium
              blend of tropical flavors with light crisp Caribbean rum. Tropical
              flavors like coconut and passion fruit are found in this smooth
              fruity collection.
            </p>
            <p>Whichever one you choose, legendary times are never far away.</p>
            <p>
              For more information about Captain Morgan, visit{" "}
              <a href="http://www.us.captainmorgan.com/">
                www.us.captainmorgan.com
              </a>
              .
            </p>
            <p>DRINK RESPONSIBLY. CAPTAIN’S ORDERS. ©</p>
            <img src={`${Usvi_Domain}/img/captain_swords.png`} alt="Captain Morgan sword" />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
