import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Contact() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Contact Us</h1>
            <p>
              <em>
                <strong>
                  Thank you for your interest in Diageo USVI.
                  <br />
                  For general inquiries, call 340-713-5600.
                  <br />
                </strong>
              </em>
            </p>
            <p>
              <strong>
                For Captain Morgan Product Compliments and Questions contact:
                <br />
              </strong>
              <a href="mailto:captainmorgan@consumer-care.net">
                CaptainMorgan@consumer-care.net
              </a>
            </p>
            <p>
              <strong>
                For Captain Morgan Experience Center Inquiries contact:
                <br />
              </strong>
              WANDA EVANGELISTA
              <br />
              <a href="mailto:wanda.evangelista@diageo.com">Wanda.Evangelista@diageo.com</a>
            </p>
            <p>
              <strong>For News Media Inquiries Only, contact:</strong>
              <br />
              CYNTHIA ARNOLD
              <br />
              <a href="mailto:cynthia.arnold@diageo.com">
                Cynthia.Arnold@diageo.com
              </a>
            </p>
            <p>
              <strong>For Career Inquiries, visit</strong>&nbsp;
              <a href="http://www.diageo-careers.com/">Diageo Careers</a>.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
