import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function News() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content news">
            <h1>News</h1>
            <ul>
              <li>
                <a href="/news/news20191022">
                  Diageo USVI Names Cynthia Arnold Vice President, Operations
                </a>
                <span className="posted">October 22, 2019</span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
