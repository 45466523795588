import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Social() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Social Responsibility</h1>
            <p>
              Diageo is proud of its brands and its commitment to setting the
              standard for social responsibility and responsible marketing. Over
              the past decade, we’ve worked closely with governments, the public
              health community and licensing authorities-plus other beer, wine
              and spirit producers-to help develop policies and initiatives that
              promote responsible drinking and discourage alcohol misuse.
            </p>
            <p>
              An innovative resource developed by Diageo is{" "}
              <a href="http://www.drinkiq.com/">DRINKiQ.com</a>, a website
              intended to help combat alcohol misuse and promote responsible
              drinking through the sharing of best practice tools, information
              and initiatives. It contains loads of resources about the effects
              of alcohol on the body, ways to talk to your kids about alcohol,
              and helpful hints and tips for{" "}
              <a href="http://www.thebar.com/responsible-drinking">
                hosting parties
              </a>{" "}
              and much, much more.
            </p>
            <p>
              The Foundation for Advancing Alcohol Responsibility (
              <a href="http://responsibility.org/">FAAR</a>) is a leader in the
              fight to eliminate drunk driving and underage drinking and
              promotes responsible decision making when drinking alcohol. Its
              innovative programs such as the b4udrink educator and Girl Talk
              aim to get young people, especially, to think about the choices
              they make. The Foundation for Advancing Alcohol Responsibility is
              sponsored by Diageo and other leading distillers in the U.S.{" "}
              <a href="http://www.responsibility.org/">
                http://www.responsibility.org/
              </a>
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
