import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Diageocares() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Diageo USVI C.A.R.E.s</h1>
            <p>
              Diageo USVI C.A.R.E.s is an employee-led group created to connect
              team members with each other and the local communities of the USVI
              through personal development, volunteerism, family and social
              events. Diageo USVI&nbsp;C.A.R.E.s is committed to appreciating
              the diversity among employees of Diageo USVI and the territory at
              large. In addition to charitable works, Diageo USVI C.A.R.E.s also
              engages in team-building for employees.
            </p>
            <p>
              With the full support of the company, a small group of employees
              launched Diageo USVI C.A.R.E.s in 2012 to support one another on
              our career journeys in Diageo. The group also established that
              part of its purpose would be to get together and volunteer in the
              community.
            </p>
            <p>
              Among a variety of community events, Diageo USVI C.A.R.E.s has
              held a “Bowl for Charity” in memory of former employee{" "}
              <em>Nemecio “Mecio” Camacho III</em>, beach clean-ups, painting
              and clean-up projects around St. Croix including the Vincent Mason
              Pool, school supplies drives, holiday decorating at the Herbert
              Grigg Home for the Aged, VI/PR Friendship events, Diageo USVI
              Learning Skills for Life training, tree planting in Diageo’s
              neighboring Harvey Community and more. In addition, a peer
              mentoring program helps employees work toward their career
              ambitions and additional opportunities within Diageo, and a health
              and fitness day is hosted each year for employees and their
              families.
            </p>
            <p>
              Ensuring our day to day operations are running well is a high
              priority, of course, but it is also important that our employees
              have opportunities to grow and lead. Diageo USVI C.A.R.E.s has
              been an excellent means for doing both.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
