import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Community() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Community</h1>
            <p>
              Diageo is proud of its brands and its commitment to setting the
              standard for social responsibility and responsible marketing. Over
              the past decade, we’ve worked closely with governments, the public
              health community and licensing authorities-plus other beer, wine
              and spirit producers-to help develop policies and initiatives that
              promote responsible drinking and discourage alcohol misuse.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
