import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  NavItem,
  Container,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Usvi_Domain } from "../constant";
import { useState } from "react";

export default function Header() {
  const [show1, setShow1] = useState(false)
  const showDropdown1 = (e)=>{
    setShow1(!show1)
    setShow2(false)
  }
  const hideDropdown1 = (e)=>{
    setTimeout(() => {
      setShow1(false)
    }, 100);
  }

  const [show2, setShow2] = useState(false)
  const showDropdown2 = (e)=>{
    setShow2(!show2)
    setShow1(false)
  }
  const hideDropdown2 = (e)=>{
    setTimeout(() => {
      setShow2(false)
    }, 100);
  }
  return (
    <header>
      <Container>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          className="navbar"
        >
          <LinkContainer to="/">
            <Navbar.Brand href="#home">
              <Image
                src={`${Usvi_Domain}/img/logo.png`}
                alt="logo not found"
                className="img-fluid"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer to="/">
                <NavItem className="nav-link">HOME</NavItem>
              </LinkContainer>

              <NavDropdown title="About Us" id="about-us-dropdown" 
              show={show1}
              onMouseEnter={showDropdown1}
              onMouseLeave={hideDropdown1}
              >
                <LinkContainer to="/about">
                  <NavDropdown.Item>About Us</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/about/products">
                  <NavDropdown.Item>Our Products</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/about/social">
                  <NavDropdown.Item>Social Responsibility</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/about/careers">
                  <NavDropdown.Item>Careers</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="Community Engagement" id="community-Engagement-dropdown"
              show={show2}
              onMouseEnter={showDropdown2}
              onMouseLeave={hideDropdown2}
              >
                <LinkContainer to="/community">
                  <NavDropdown.Item>Community Engagement</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/community/diageocares">
                  <NavDropdown.Item>Diageo USVI C.A.R.E.s</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/community/sponsorship">
                  <NavDropdown.Item>Sponsorship & Donations</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/news">
                <NavItem className="nav-link">News</NavItem>
              </LinkContainer>

              <LinkContainer to="/contact">
                <NavItem className="nav-link">Contact Us</NavItem>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
