import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";

export default function HomeSlider() {
  return (
    <div className="home_slider_wrapper">
      <Carousel>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            src="./img/slider-img-1.jpg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            src="./img/slider-img-2.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <p>Supplying the U.S. with quality Caribbean rum</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
