import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Careers() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Careers</h1>
            <p>
              <strong>Working at Diageo USVI</strong>
            </p>
            <p>
              Our success as a business depends on the success of our people. So
              we strive to create an environment where all our employees feel
              included and able to perform at their best.
            </p>
            <p>
              We celebrate success, no matter how big or small. We trust each
              other and value openness and honesty in our working
              relationships.&nbsp; Our performance management process provides
              opportunities for everyone in Diageo USVI&nbsp;to share their
              broader goals in life and supports them in exploring and
              harnessing their unique strengths at work.
            </p>
            <p>
              More generally we recognize that our employees have commitments
              away from the office and encourage work-life balance. &nbsp;We
              also aim to attract and maintain a diverse employee base with a
              wide variety of individual characteristics, perspectives and
              experiences in every part of our business.
            </p>
            <p>
              For information about available opportunities, visit&nbsp;
              <a
                href="http://www.diageo-careers.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.diageo-careers.com
              </a>
              .
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
