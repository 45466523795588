import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Sponsorship() {
  return (
    <>
      <Header />
      <div className="main-div">
        <Container>
          <div className="content">
            <h1>Sponsorship &amp; Donations</h1>
            <p>
              Diageo USVI is a concerned corporate citizen of the U.S. Virgin
              Islands.&nbsp;&nbsp;We support charitable programs, non-profit
              organizations and events throughout the territory.&nbsp; We
              support the community with employee volunteers through our Diageo
              USVI C.A.R.E.s group. Through our&nbsp;grants,
              sponsorships&nbsp;and partnerships with community based
              organizations and charities,&nbsp;we provide support in the form
              of monetary donations, donations of our beverage alcohol products,
              branded apparel, Responsible Drinking materials and in-kind use of
              our Captain Morgan Visitor Center.
            </p>
            <p>
              Diageo&nbsp;USVI community investments and support focus on four
              core areas:{" "}
              <em>
                Responsible Drinking, Education &amp; Workforce Development (in
                the engineering, technical trades and hospitality fields),
                Energy &amp; Environment{" "}
              </em>
              and
              <em>
                {" "}
                Quality of Life &amp; Celebrating Virgin Islands Culture.
              </em>
            </p>
            <p>
              <strong>
                Given the nature of our business in the beverage alcohol
                industry and our commitment to social responsibility, we
                concentrate our donations on events and programs that primarily
                reach adults of legal drinking age, 18 years of age or older.
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <em>
                For support or sponsorship for your non-profit organization or
                cause, please fill out the form below and email us at{" "}
                <strong>
                  <a href="mailto:dusvisponsorships@diageo.com">
                    dusvisponsorships@diageo.com
                  </a>
                </strong>
                .
              </em>
            </p>
              <ul>
                <li>
                  <a href="../docs/DUSVI New Sponsorship Application.pdf" target="_blank" rel="noreferrer">
                    New Sponsorship Application
                  </a>
                </li>
                <li>
                  <a href="../docs/DUSVI Renewal Sponsorship Application_Template.pdf" target="_blank" rel="noreferrer">
                    Renewal Sponsorship Application
                  </a>
                </li>
              </ul>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
